<!--集成SDK-->
<template>
  <div class="container content_container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row" style="position: relative">
          <div class="col-sm-12 col-md-6 offset-md-1">
            <img src="@assets/img_2-5@2x.png" />
          </div>
          <div class="col-md-5 d-none d-md-block"></div>
          <div class="info_content col-12">
            <span class="info_title">
              <span class="info_decorate"></span>
              <span style="position: relative" v-html="rightTitle"></span>
            </span>
            <div class="info_detail_container">
              <div
                class="info_item_detail"
                v-for="(item, index) in rightDetail"
                :key="index"
                v-html="item"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  name: "onlineSettingPart",
  setup() {
    let rightTitle = ref("<a href='https://wiki2.bishengoffice.com/zh/%E9%A2%84%E8%A7%88%E5%8D%8F%E5%90%8C%E7%BC%96%E8%BE%91%E9%9B%86%E6%88%90'>在线office集成</a>");
    let rightDetail = [
      "在线office可以与OA、IM等各类业务系统集成，为业务系统提供在线office在线预览，系统编辑的能力",
      "前端API：可完成业务系统文件的各类处理。例如：定位到，提取/修改文件中的数据，监听excel中的各类事件等",
      "服务端API：支持公文套红、数据的填充与提取，文件合并、文件清稿、文件标注、向excel数据批量插等应用场景",
    ];
    return {
      rightTitle,
      rightDetail,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.content_container {
  margin-top: 52px;
  img {
    width: 100%;
  }
  .info_content {
    // position: absolute;
    // top: 0px;
    // right: 0px;
    // text-align: right;
    .info_title {
      height: 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #242424;
      position: relative;
    }
    .info_decorate {
      width: 64px;
      height: 8px;
      background: #00b8fc;
      position: absolute;
      right: 0px;
      top: 30px;
    }
    .info_detail_container {
      margin-top: 20px;
      text-align: left;
      .info_item_detail {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #545c76;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (min-width: 576px) {
  .info_content {
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .info_content {
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: right;
    width: 35%;
  }
}
@media screen and (min-width: 768px) {
  .content_container {
    margin-top: 200px;
  }
}
@media screen and (min-width: 1000px) {
  .content_container {
    margin-top: 100px;
  }
}
</style>

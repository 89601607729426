<!--底部在线协同办公-->
<template>
  <div
    class="office_container container_fluid"
    style="background: #f5f7fa; padding-top: 99px"
  >
    <div class="container_fluid">
      <div class="office_operate row justify-content-center">
        <div class="col-11 col-md-12">
          <div class="row">
            <div class="office_operate_title">{{ officeOperateTitle }}</div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="row justify-content-center">
                <div class="img_container col-12">
                  <img
                    src="@assets/bg2-1@2x.png"
                    style="
                      width: 100%;
                      max-width: 1100px;
                      display: block;
                      margin: 0 auto;
                    "
                  />
                  <div class="front_img d-none d-md-block">
                    <img src="@assets/front_img@2x.png" />
                  </div>
                  <div class="office_operate_detail_container">
                    <div class="office_operate_detail_title">
                      <span class="info_decorate"></span>
                      <span style="position: relative">{{
                        officeOperateDetailTitle
                      }}</span>
                    </div>
                    <div
                      class="office_operate_detail"
                      v-for="(item, index) in officeOperateDetail"
                      :key="index"
                      v-html="item"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div @click="onExpirenceClick" class="experience_btn"></div>
        </div>
      </div>
    </div>
    <!--文档内容权限在线设置-->
    <onlineSetting></onlineSetting>
    <!--文档支持-->
    <docSupport></docSupport>
    <!--集成SDK-->
    <sdkPart></sdkPart>
    <!--底部footer-->
    <footerPart></footerPart>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import onlineSetting from "./onlineSettingPart.vue";
import docSupport from "./docSupportPart.vue";
import sdkPart from "./sdkPart.vue";
import footerPart from "./footerPart.vue";
export default {
  name: "officeOperatePart",
  setup() {
    let officeOperateTitle = ref("OFFICE在线协同办公");
    let officeOperateDetailTitle = ref("Office组件兼容协同");
    let officeOperateDetail = [
      "Word/excel/ppt三组件支持多人在线协同编辑，兼容桌面office的核心功能以及使用习惯",
      "提供强大的前后端API满足各类应用场景下对在线office的需求",
      "提供完整的集成解决方案，可以将在线office与各业务系统集成",
    ];
    return {
      officeOperateTitle,
      officeOperateDetailTitle,
      officeOperateDetail,
    };
  },
  components: {
    onlineSetting,
    docSupport,
    sdkPart,
    footerPart,
  },
  methods: {
    onExpirenceClick() {
      let uriprefix = window.uriprefix||"";
      location.href = uriprefix+"/apps/drive/";
    },
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.office_container {
  .office_operate {
    margin: 0 auto;
    position: relative;
    .office_operate_title {
      margin: 0 auto;
      font-size: 36px;
      font-family: STZhongsong;
      font-weight: 400;
      color: #000000;
      transform: translate(0, -20px);
    }
    .experience_btn {
      width: 180px;
      height: 48px;
      background-repeat: no-repeat;
      background-size: 180px 48px;
      background-image: url("@assets/operate_btn@2x.png");
      margin: 42px auto 0;
    }
    @media (min-width: 768px) {
      .office_operate_detail_container {
        position: absolute;
        left: 5%;
        top: 15%;
        width: 45%;
      }
      .experience_btn {
        cursor: pointer;
        margin-top: 140px;
      }
    }

    .office_operate_detail_container {
      .office_operate_detail_title {
        width: 100%;
        height: 31px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #242424;
        position: relative;
        .info_decorate {
          width: 130px;
          height: 8px;
          background: #00b8fc;
          position: absolute;
          left: 155px;
          top: 35px;
        }
      }
      .office_operate_detail {
        width: 100%;
        // height: 30px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #545c76;
        &:nth-child(2) {
          margin-top: 37px;
        }
      }
    }
    .img_container {
      width: 100%;
      max-width: 1100px;
      position: relative;
      margin: 0 auto;
      .front_img {
        width: 68%;
        max-width: 823px;
        position: absolute;
        top: 51%;
        left: 13%;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

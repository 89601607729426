import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/components/HomePage";
let uriprefix = window.uriprefix||"";
let routes = [
  { path: uriprefix+"/", name: "homePage", component: HomePage },
  { path: uriprefix+"/apps/index", name: "loginTypePage", component: HomePage }, // 登录选择界面
];
const router = createRouter({
  routes,
  history: createWebHistory(),
});
export default router;

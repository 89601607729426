<template>
  <div class="main_container">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row">
            <div class="col-md-5 col-sm-12 order-md-2 left_content">
              <div class="left_top">
                <img src="@assets/2@2x.png" />
              </div>
              <div class="left_bottom d-none d-md-block">
                <img src="@assets/002@2x.png" />
              </div>
              <div class="info_content">
                <span class="info_title">
                  <span class="info_decorate"></span>
                  <span>{{ rightTitle }}</span>
                </span>
                <div class="info_detail_container d-none d-md-block">
                  <div
                    class="info_item_detail"
                    v-for="(item, index) in rightDetail"
                    :key="index"
                    v-html="item"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12 order-md-1">
              <div class="col-12" style="position: relative">
                <img
                  class="team_space col-12"
                  src="@assets/team_space@2x.png"
                  style="position: relative; left: -40px"
                />
                <img
                  class="team_share col-12"
                  src="@assets/team_share@2x.png"
                  style="position: absolute; left: 0%; top: 40%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let rightTitle = ref("多层次文件共享协同");
    let rightDetail = [
      "<span><a href='https://wiki2.bishengoffice.com/zh/%E6%96%87%E4%BB%B6%E5%85%B1%E4%BA%AB%E5%8D%8F%E5%90%8C'>文件共享</a></span>：可按需将个人文件，团队文件共享给通讯录中人或者部门",
      "<span><a href='https://wiki2.bishengoffice.com/zh/%E5%9B%A2%E9%98%9F%E7%A9%BA%E9%97%B4%E5%8D%8F%E5%90%8C'>团队协同</a></span>：独立的回收站；可给用户或部门分配不同的权限角色;",
      "<span><a href='https://wiki2.bishengoffice.com/zh/%E5%85%AC%E5%BC%80%E9%93%BE%E6%8E%A5%E5%8D%8F%E5%90%8C'>公开共享</a></span>：文件，文件夹可以公开共享，匿名访问",
      "<span><a href='https://wiki2.bishengoffice.com/zh/%E6%A0%87%E7%AD%BE%E5%8D%8F%E5%90%8C'>标签共享</a></span>：文件进行标签分类，并将标签分享给其他用户或部门",
      "<span><a href='https://wiki2.bishengoffice.com/zh/%E5%86%85%E5%AE%B9%E5%8D%8F%E5%90%8C'>内容协同</a></span>：在office文件批注中@其他用户或文件并发送通知",
    ];
    return {
      rightTitle,
      rightDetail,
    };
  },
};
</script>

<style scoped lang="less">
@import "@/style/common.less";
.main_container {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 62px;
  .container {
    .left_content {
      .left_top {
        text-align: right;
        img {
          width: 30%;
          max-width: 120px;
        }
      }
      .left_bottom {
        margin-top: 73px;
        img {
          width: 67%;
          max-width: 274px;
        }
      }
      .info_content {
        text-align: right;
        position: absolute;
        right: 20px;
        top: 45px;
        .info_title {
          height: 30px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #242424;
          position: relative;
        }
        .info_decorate {
          width: 97px;
          height: 8px;
          background: #00b8fc;
          position: absolute;
          left: 0px;
          top: 35px;
          z-index: -1;
        }
        .info_detail_container {
          margin-top: 15px;
          text-align: left;
          .info_item_detail {
            // height: 30px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #545c76;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>

<!--文件在线设置组件-->
<template>
  <div class="container content_container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row">
          <div class="col-sm-12 col-md-8">
            <img src="@assets/computer_set@2x.png" />
          </div>
          <div class="col-md-4" style="position: relative">
            <img class="d-none d-md-block" src="@assets/img_2-2@2x.png" />
            <div class="info_content">
              <span class="info_title">
                <span class="info_decorate"></span>
                <span style="position: relative" v-html="rightTitle"></span>
              </span>
              <div class="info_detail_container">
                <div
                  class="info_item_detail"
                  v-for="(item, index) in rightDetail"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  name: "onlineSettingPart",
  setup() {
    let rightTitle = ref(
      "<a style='' href='https://wiki2.bishengoffice.com/zh/office%E5%86%85%E5%AE%B9%E6%9D%83%E9%99%90'>文档内容区域权限设置</a>"
    );
    let rightDetail = [
      "word内容权限：可实现关键字段或者区域的不同用户只读，编辑，或者隐藏权限控制",
      "word章节权限：可以将word中不同的章节分配权限，控制不同的用户对章节的只读，编辑权限",
      "excel区域权限：可以将excel数据区域划分为不同的权限，控制不同用户只读，编辑，或者隐藏区域",
    ];
    return {
      rightTitle,
      rightDetail,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.content_container {
  margin-top: 52px;
  img {
    width: 100%;
  }
  .info_content {
    .info_title {
      height: 31px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #242424;
      position: relative;
    }
    .info_decorate {
      width: 130px;
      height: 8px;
      background: #00b8fc;
      position: absolute;
      left: 130px;
      top: 35px;
    }
    .info_detail_container {
      margin-top: 20px;
      text-align: left;
      // height: 55px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #545c76;
      .info_item_detail {
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (min-width: 576px) {
  .info_content {
    position: relative;
    text-align: left;
  }
}
@media screen and (min-width: 768px) {
  .info_content {
    text-align: right;
    position: absolute;
    top: 5%;
    right: 10%;
    .info_detail_container {
      // width: 378px;
    }
  }
}

@media screen and (min-width: 768px) {
  .content_container {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1000px) {
  .content_container {
    margin-top: 50px;
  }
}
</style>

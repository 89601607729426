<!--设置-->
<template>
  <div class="main_container">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row">
            <div class="col-md-5 col-sm-12 left_content">
              <div class="left_top">
                <img src="@assets/3@2x.png" />
              </div>
              <div class="left_bottom d-none d-md-block">
                <img src="@assets/003@2x.png" />
              </div>
              <div class="info_content">
                <span class="info_title">
                  <span class="info_decorate"></span>
                  <span style="position: relative" v-html="leftTitle"></span>
                </span>
                <div class="info_detail_container d-none d-md-block">
                  <div
                    class="info_item_detail"
                    v-for="(item, index) in leftDetail"
                    :key="index"
                    v-html="item"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12" style="position: relative">
              <img
                class="setting_right col-12"
                src="@assets/setting@2x.png"
                style="position: relative"
              />
              <img
                class="setting_role col-12"
                src="@assets/setting_role@2x.png"
                style="position: absolute; right: 5%; top: 30%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let leftTitle = ref(
      "<a style='' href='https://wiki2.bishengoffice.com/zh/%E6%9D%83%E9%99%90%E4%BD%93%E7%B3%BB'>完善的权限体系</a>"
    );
    let leftDetail = [
      "基于角色授权：针对系统中不同资源定义不同操作权限的角色，在进行协同时，将不同的用户或者部门分配不同的角色即可",
      "可继承的授权：文件、目录将自动继承上层授权；有成员、部门均可继承上级部门分别的角色",
      "可自定义角色：可修改常用内置角色，也可根据创建自定义角色",
    ];
    return {
      leftTitle,
      leftDetail,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.main_container {
  width: 100%;
  position: relative;
  padding-top: 40px;
  padding-bottom: 94px;
  .left_content {
    .left_top {
      img {
        width: 30%;
        max-width: 120px;
      }
    }
    .left_bottom {
      margin-top: 39px;
      text-align: right;
      img {
        width: 67%;
        max-width: 274px;
      }
    }
    .info_content {
      position: absolute;
      left: 20px;
      top: 30px;
      max-width: 450px;
      .info_title {
        height: 30px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #242424;
        position: relative;
      }
      .info_decorate {
        width: 125px;
        height: 8px;
        background: #00b8fc;
        position: absolute;
        left: 98px;
        top: 35px;
      }
      .info_detail_container {
        margin-top: 15px;
        .info_item_detail {
          // height: 30px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #545c76;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<!--集成开发-->
<template>
  <div class="main_container">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row">
            <div class="col-md-5 col-sm-12 order-md-2 left_content">
              <div class="left_top">
                <img src="@assets/4@2x.png" />
              </div>
              <div class="left_bottom d-none d-md-block">
                <img src="@assets/004@2x.png" />
              </div>
              <div class="info_content">
                <span class="info_title">
                  <span class="info_decorate"></span>
                  <span style="position: relative" v-html="rightTitle"></span>
                </span>
                <div class="info_detail_container d-none d-md-block">
                  <div
                    class="info_item_detail"
                    v-for="(item, index) in rightDetail"
                    :key="index"
                    v-html="item"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12 order-md-1">
              <div class="col-12">
                <img class="col-12" src="@assets/api_program@2x.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let rightTitle = ref("<a style='' href='https://wiki2.bishengoffice.com/zh/%E9%9B%86%E6%88%90%E7%BD%91%E7%9B%98sdk'>云盘SDK集成</a>");
    let rightDetail = [
      "云盘SDK：可以将云盘的常用功能（例如：文件列表，文件上传，打开云盘中的文件，文件共享等）集成到您的系统中，可以轻松在您的业务系统中实现附件的管理。",
      "您可以更加专注其他更核心的业务，云盘SDK助您实现各业务系统的附件统一存储，统一管理，统一检索"
    ];
    return {
      rightTitle,
      rightDetail,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.main_container {
  // height: 640px;
  width: 100%;
  position: relative;
  padding-top: 83px;
  padding-bottom: 14px;
  .left_content {
    text-align: right;
    .left_top {
      img {
        width: 30%;
        max-width: 117px;
      }
    }
    .left_bottom {
      margin-top: 135px;
      img {
        width: 100%;
        max-width: 274px;
      }
    }
    .info_content {
      position: absolute;
      right: 20px;
      top: 45px;
      .info_title {
        height: 30px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #242424;
        position: relative;
      }
      .info_decorate {
        width: 125px;
        height: 8px;
        background: #00b8fc;
        position: absolute;
        left: 0px;
        top: 35px;
      }
      .info_detail_container {
        margin-top: 15px;
        text-align: left;
        .info_item_detail {
          // height: 30px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #545c76;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="container">
    <div class="row">
      <span class="contract_item"
        ><a
          href="https://wiki.bishengoffice.com/zh/%E5%85%B6%E4%BB%96/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE"
          >用户协议</a
        ></span
      >
      <span class="contract_item"
        ><a
          href="https://wiki.bishengoffice.com/zh/%E5%85%B6%E4%BB%96/%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC"
          >联系我们</a
        ></span
      >
      <span class="contract_item"
        ><a
          href="https://wiki.bishengoffice.com/zh/%E5%85%B6%E4%BB%96/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC"
          >加入我们</a
        ></span
      >
      <!-- <span class="contract_item"
        ><a href="https://v3.bishengoffice.com" target="blank"
          >以往版本</a
        ></span
      > -->
    </div>
    <div class="row">
      <span class="bei_an_info"
        ><a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802027903"
          target="blank"
          >京公网安备 11010802028782号</a
        ></span
      >
      <span class="police_info">
        <img src="@assets/beian@2x.png" style="width: 16px; height: 18px" />
      </span>
      <span class="bei_an_info">
        <a href="https://beian.miit.gov.cn/"
          >工业和信息化部备案管理系统网站 京ICP备19018207号-1</a
        ></span
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.container {
  margin-top: 110px;
}
.row {
  justify-content: center;
  &:nth-child(1) {
    gap: 30px;
  }
  &:nth-child(2) {
    margin-top: 15px;
  }
  &:nth-child(3) {
    margin-top: 10px;
    align-items: center;
    img {
      margin: 0 4px;
    }
  }
}
.contract_item {
  width: 60px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #545c76;
  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}
.cmp_name {
  height: 13px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #848894;
}
.bei_an_info {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #848894;
  a {
    text-decoration: none;
    color: #848894;
  }
}
.police_info {
  align-items: center;
  display: flex;
}
</style>

<!--顶部的方案说明-->
<template>
  <div class="container d-none d-md-block">
    <div class="row">
      <div class="col-12 title_cla">{{ title }}</div>
    </div>
    <div class="row">
      <div class="col-12 desc_cla desc_cla_one">{{ des1 }}</div>
    </div>
    <div class="row">
      <div class="col-12 desc_cla">{{ des2 }}</div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let title = ref("企业级在线OFFICE，云端网盘多人协作");
    let des1 = ref("兼容Office格式（ Word | Excel | PowerPoint ）为各应用场景提供解决方案");
    let des2 = ref("企业团队云存储空间，多层协作，灵活部署");
    return {
      title,
      des1,
      des2,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.container {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}
.title_cla {
  line-height: 35px;
  height: 35px;
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.desc_cla {
  line-height: 21px;
  height: 21px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 7px;
}
.desc_cla_one {
  margin-top: 44px;
}
</style>

<!--轮播图-->
<template>
  <div class="pic_container">
    <img
      v-for="(item, index) in pics"
      :src="item"
      :key="index"
      :class="'cla_' + index"
      :id="'pic_' + index"
    />
  </div>
  <div class="dot_container">
    <span
      v-for="(item, index) in pics"
      :key="index"
      class="dot_item"
      @click="onDotClick(index)"
      :class="{ dot_item_active: index == dotActIndex }"
    ></span>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    // 所有的图片
    let pics = ref([
      require("@assets/pic1.png"),
      require("@assets/pic2.png"),
      require("@assets/pic3.png"),
      require("@assets/pic4.png"),
    ]);
    // 选中的索引
    let dotActIndex = ref(0);
    // 动画时间
    let time = 2000;
    return { pics, time, dotActIndex };
  },
  mounted() {
    this.startAnim();
  },
  computed: {},
  methods: {
    // 设置初始化的4种样式，开启定时器，先触发样式，再进行替换
    startAnim() {
      let firstTime = setTimeout(() => {
        this.dotActIndex++;
        if (this.dotActIndex >= 4) {
          this.dotActIndex = 0;
        }
        for (let i = 0; i < this.pics.length; i++) {
          var ind = 0;
          if (i - this.dotActIndex < 0) {
            ind = i - this.dotActIndex + this.pics.length;
          } else {
            ind = i - this.dotActIndex;
          }
          document.getElementById("pic_" + i).className = "cla_" + ind;
        }
        clearTimeout(firstTime);
        let secondTime = setTimeout(() => {
          clearTimeout(secondTime);
          this.startAnim();
        }, this.time);
      }, this.time);
    },
    // 点击的时候对于图片进行排列，并进行样式的重置
    onDotClick(index) {
      this.dotActIndex = index;
      for (let i = 0; i < this.pics.length; i++) {
        var ind = 0;
        if (i - this.dotActIndex < 0) {
          ind = i - this.dotActIndex + this.pics.length;
        } else {
          ind = i - this.dotActIndex;
        }
        document.getElementById("pic_" + i).className = "cla_" + ind;
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.pic_container {
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 50%;
  img {
    width: 60%;
    &:first-child {
      box-shadow: 0px 0px 40px rgb(0 0 0 / 30%);
    }
  }
}
.cla_0 {
  transform: skewY(0deg);
  transform-origin: right;
  position: absolute;
  left: 0px;
  z-index: 4;
  transition: all 0.5s;
}
.cla_1 {
  transform: skewY(5deg);
  transform-origin: right;
  position: absolute;
  left: 15%;
  top: -10%;
  z-index: 3;
  transition: all 0.5s;
}
.cla_2 {
  transform: skewY(5deg);
  transform-origin: right;
  position: absolute;
  left: 20%;
  z-index: 2;
  top: -20%;
  transition: all 0.5s;
}
.cla_3 {
  transform: skewY(5deg);
  transform-origin: right;
  position: absolute;
  left: 25%;
  z-index: 1;
  top: -30%;
  transition: all 0.5s;
}
.dot_container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 10%;
}
.dot_item {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #d5d8db;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}
.dot_item_active {
  background: #8e99a9;
}
</style>

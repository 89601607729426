<!--在线企业云网盘组件-->
<template>
  <div class="main_container container">
    <div class="title_container row justify-content-center">
      <div class="col-12">{{ title }}</div>
    </div>
    <div class="content_container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-md-5 left_content">
              <div class="left_top">
                <img src="@assets/1@2x.png" />
              </div>
              <div class="left_bottom d-none d-md-block">
                <img src="@assets/001@2x.png" />
              </div>
              <div class="info_content">
                <span class="info_title">
                  <span class="info_decorate"></span>
                  <span>{{ leftTitle }}</span>
                </span>
                <div class="info_detail_container d-none d-md-block">
                  <div
                    class="info_item_detail"
                    v-for="(item, index) in leftDetail"
                    :key="index"
                    v-html="item"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-7">
              <img class="col-12" src="@assets/private_share@2x.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let title = ref("在线企业云网盘，满足多层次协同需求");
    let leftTitle = ref("企业云盘");
    let leftDetail = [
      "支持<a href='https://wiki2.bishengoffice.com/zh/%E8%B4%A6%E5%8F%B7%E4%BD%93%E7%B3%BB'><span style='font-weight: bold'>单租户</span>、<span style='font-weight: bold'>多租户</span></a>组织结构,满足各类规模客户的需求",
      "支持文件协同，团队协同，文件的链接公开共享，以及文件分类标签共享",
      "支持多种环境下的多种形式的灵活部署，配置灵活，运营维护简单",
    ];
    return {
      title,
      leftTitle,
      leftDetail,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.main_container {
  width: 100%;
  position: relative;
  margin-top: 20%;
  padding-bottom: 16px;
  .title_container {
    font-size: 36px;
    font-family: STZhongsong;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .content_container {
    margin-top: 73px;
    .left_content {
      position: relative;
      .left_top {
        img {
          width: 20%;
          max-width: 54px;
          margin-left: 39px;
        }
      }
      .left_bottom {
        margin-top: 67px;
        position: relative;
        img {
          width: 100%;
          max-width: 274px;
        }
        .left_content_detail {
          width: 174px;
          height: 35px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #545c76;
        }
      }
      .info_content {
        position: absolute;
        left: 89px;
        top: 50px;
        .info_title {
          height: 30px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #242424;
          position: relative;
        }
        .info_decorate {
          position: absolute;
          width: 64px;
          height: 8px;
          background: #00b8fc;
          position: absolute;
          left: 63px;
          top: 35px;
          z-index: -1;
        }
        .info_detail_container {
          margin-top: 15px;
          .info_item_detail {
            // height: 30px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #545c76;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <header class="header_container fixed-top">
    <nav
      class="navbar navbar-expand-lg navbar-light"
      style="background: #395489 !important; max-width: 1120px; margin: 0 auto"
    >
      <a class="navbar-brand" href="#">
        <img src="@assets/logo@2x.png" style="width: 30px; height: 30px" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">毕升网盘</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="https://bamboo.bishengoffice.com/zh/home">Office新内核</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://wiki2.bishengoffice.com/">帮助文档</a>
          </li>
          <li class="nav-item login_btn_container">
            <span @click="onLoginType" class="login_btn"></span>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <div class="top_container">
    <img src="@assets/bg01@2x.png" />
    <topLabelPart></topLabelPart>
    <picsPart></picsPart>
  </div>
  <drivePart></drivePart>
  <teamSharePart></teamSharePart>
  <settingPart></settingPart>
  <apiPart></apiPart>
  <officeOperatePart></officeOperatePart>
</template>

<script>
import topLabelPart from "./homePage/topLabelPart.vue";
import picsPart from "./homePage/picsPart.vue";
import drivePart from "./homePage/drivePart.vue";
import teamSharePart from "./homePage/teamShare.vue";
import settingPart from "./homePage/settingPart.vue";
import apiPart from "./homePage/apiPart.vue";
import officeOperatePart from "./homePage/officeOperatePart.vue";

export default {
  name: "HomePage",
  props: {},
  components: {
    topLabelPart,
    picsPart,
    drivePart,
    teamSharePart,
    settingPart,
    apiPart,
    officeOperatePart,
  },
  methods: {
    onLoginType() {
      let uriprefix = window.uriprefix||"";
      location.href = uriprefix+"/apps/drive/";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/style/common.less";
@import "@/style/homePage.less";

.navbar-light .navbar-toggler {
  border-color: white;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27white%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}
.navbar-light .navbar-nav .nav-item .nav-link {
  // height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
}
@media screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    position: relative;
    width: 100%;
  }
  .login_btn_container {
    position: absolute;
    right: 0px;
  }
  .nav-item {
    &:first-child {
      margin-left: 52px;
    }
  }
}
</style>

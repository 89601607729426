<!--文档支持-->
<template>
  <div class="container content_container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row">
          <div
            class="col-sm-12 col-md-8 order-2 order-sm-2 order-md-1"
            style="position: relative"
          >
            <img
              class="col-md-8 d-none d-md-block"
              src="@assets/img_2-4@2x.png"
            />

            <div class="info_content">
              <span class="info_title">
                <span class="info_decorate"></span>
                <span style="position: relative">{{ leftTitle }}</span>
              </span>
              <div class="info_detail_container">
                <div
                  class="info_item_detail"
                  v-for="(item, index) in leftDetail"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
              <span class="info_title_1">
                <span class="info_decorate_1"></span>
                <span style="position: relative">{{ leftTitle_1 }}</span>
              </span>
              <div class="info_detail_container">
                <div
                  class="info_item_detail"
                  v-for="(item, index) in leftDetail_1"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4"></div> -->
          <div class="col-md-4 order-1 order-sm-1 order-md-2">
            <img src="@assets/img_2-3@2x.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  name: "onlineSettingPart",
  setup() {
    let leftTitle = ref("支持excel跨文件数据引用");
    let leftDetail = [
      "<a style='' href='https://wiki2.bishengoffice.com/zh/excel%E8%B7%A8%E6%96%87%E4%BB%B6%E5%BC%95%E7%94%A8%E6%95%B0%E6%8D%AE'>数据链接</a>：支持其他线上excel文件的数据,并实现同步更新",
      "<a style='' href='https://wiki2.bishengoffice.com/zh/%E6%96%87%E4%BB%B6%E9%93%BE%E6%8E%A5'>文件链接</a>：超链接支持引用其他文件，点击此链接时将打开该文件",
    ];
    let leftTitle_1 = ref("word内容合并以及引用");
    let leftDetail_1 = [
      "<a style='' href='https://wiki2.bishengoffice.com/zh/word%E5%86%85%E5%AE%B9%E5%BC%95%E7%94%A8'>内容合并</a>：可将线下或线上的word，excel内容合并到word的指定位置，并保留格式，也可通过服务端API实现该能力",
      "<a style='' href='https://wiki2.bishengoffice.com/zh/%E6%96%87%E4%BB%B6%E9%93%BE%E6%8E%A5'>文件链接</a>：超链接支持引用其他文件，点击此链接时将打开该文件",
    ];
    return {
      leftTitle,
      leftDetail,
      leftTitle_1,
      leftDetail_1,
    };
  },
};
</script>
<style scoped lang="less">
@import "@/style/common.less";
.content_container {
  margin-top: 52px;
  img {
    width: 100%;
  }
  .info_content {
    // position: absolute;
    // left: 23%;
    // top: 26%;
    .info_title,
    .info_title_1 {
      height: 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #242424;
      position: relative;
    }
    .info_title_1 {
      margin-top: 50px;
      display: inline-block;
    }
    .info_decorate {
      width: 95px;
      height: 8px;
      background: #00b8fc;
      position: absolute;
      left: 140px;
      top: 35px;
    }
    .info_decorate_1 {
      width: 64px;
      height: 8px;
      background: #00b8fc;
      position: absolute;
      left: 142px;
      top: 35px;
    }
    .info_detail_container {
      margin-top: 20px;
      .info_item_detail {
        // height: 57px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #545c76;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .info_content {
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .info_content {
    position: absolute;
    top: 13%;
    left: 26%;
  }
}
@media screen and (min-width: 768px) {
  .content_container {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1000px) {
  .content_container {
    margin-top: 50px;
  }
}
</style>
